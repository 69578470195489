export const plansCategories = [
  {
    id: 'behavioralConsultation',
    title: 'Konsultacje behawioralne',
    image: 'plan-category-2.jpeg',
    imageStyle: { backgroundPositionY: '70%' },
    popup: false,
    plans: [
      {
        key: 'introducing',
        id: 'introducing',
        iconFileName: 'icon-intro-dog.png',
        progress: 1,
        title: 'Pierwsza konsultacja behawioralna',
        subtitile: 'wywiad, analiza przypadku, przygotowanie&nbsp;zaleceń',
        description: `
                Konsultacja behawioralna polega na&nbsp;przeprowadzeniu obszernego wywiadu z&nbsp;opiekunem oraz obserwacji zachowania psa na&nbsp;podstawie przesłanych wcześniej nagrań i/lub w trakcie spotkania. Następnie przeprowadzana jest analiza behawioralna psa mająca na&nbsp;celu identyfikację przyczyn niepożądanego zachowania. Na&nbsp;jej podstawie przygotowywane są&nbsp;zalecenia do dalszej pracy z&nbsp;psem zawierające potrzebne do&nbsp;wprowadzenia zmiany mające na&nbsp;celu eliminację problemów. Zalecenia przesyłane są&nbsp;wybraną drogą kontaktu do&nbsp;7 dni. Po&nbsp;otrzymaniu zaleceń decyzja o&nbsp;dalszej współpracy podejmowana jest przez klienta.
        `,
        meetings: 1,
        duration: 120,
        price: 270,
        allMeetings: 1,
        totalPrice: 270,
      },
      {
        key: 'nextMeeting',
        id: 'nextMeeting',
        iconFileName: 'icon-pro-dog.png',
        progress: 2,
        title: 'Kolejna konsultacja behawioralna',
        subtitile: 'wdrażanie zaleceń, nadzór&nbsp;i&nbsp;prowadzenie',
        description:
          'Zajęcia polegają na wspólnej realizacji przygotowanych wcześniej zaleceń, organizacji spotkania dostosowując środowisko w celu zaradzenia problemom psa i poprawie jego dobrostanu lub weryfikacji pracy opiekuna oraz przekazania uwag po wdrożeniu zmian przekazanych w zaleceniach.',
        meetings: 1,
        duration: 60,
        price: 150,
        allMeetings: 1,
        totalPrice: 150,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `
        <br/><b>
        Zajęcia polegają na wspólnej realizacji przygotowanych wcześniej zaleceń, organizacji spotkania dostosowując środowisko w&nbsp;celu zaradzenia problemom psa i&nbsp;poprawie jego dobrostanu lub weryfikacji pracy opiekuna oraz przekazania uwag po&nbsp;wdrożeniu zmian przekazanych w&nbsp;zaleceniach.
        `,
      },
    ],
  },
  {
    id: 'individualTraining',
    title: 'Szkolenia indywidualne',
    image: 'plan-category-5.jpeg',
    imageStyle: { backgroundSize: '105%', backgroundPositionY: '25%' },
    popup: true,
    plans: [
      {
        key: 'kindergarten',
        id: 'kindergarten',
        iconFileName: 'icon-puppy.png',
        modalImage: 'plan-modal-kindergarten.jpg',
        progress: 1,
        subtitile: 'do 6 msc życia',
        title: 'Psie przedszkole',
        description:
          'Szkolenie przeznaczone jest dla szczeniąt pomiędzy drugim, a&nbsp;szóstym miesiącem życia i&nbsp;składa się z&nbsp;9 spotkań (kurs)&nbsp;lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).  Pierwsze spotkanie trwa 2 godziny.',
        meetings: 1,
        duration: 60,
        price: 150,
        allMeetings: 9,
        totalPrice: 1400,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `
        <br/>Szkolenie przeznaczone jest dla szczeniąt pomiędzy drugim, a&nbsp;szóstym miesiącem życia i&nbsp;składa się z&nbsp;9 spotkań (kurs)&nbsp;lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).  Pierwsze spotkanie trwa 2 godziny.
        <br/><br/><b>Program szkolenia</b><br/><br/>
        <ul>
        <li>Ocena warunków psa w&nbsp;domu - praktyczne wskazówki jak poprawić komfort psa i&nbsp;opiekuna.</li>
        <li>Ocena spacerów -&nbsp;praktyczne wskazówki i&nbsp;instruktaż (w&nbsp;tym nauka chodzenia&nbsp;na luźnej smyczy) .</li>
        <li>Nauka&nbsp;czystości.</li>
        <li>Socjalizacja&nbsp;szczeniaka.</li>
        <li>Zapobieganie i&nbsp;eliminacja zachowań takich jak skakanie na ludzi, podgryzanie przedmiotów i&nbsp;członków rodziny, frustracja i&nbsp;stres podczas zostawiania psa samego w&nbsp;domu.</li>
        <li>Przywołanie</li>
        <li>Prawidłowa zabawa</li>
        </ul>
        <br/>
        Organizator zastrzega sobie prawo do modyfikacji zakresu szkolenia dostosowując go do indywidualnych możliwości psa.
        <br/>
        <br/>
        `,
      },
      {
        key: 'basics',
        id: 'basics',
        iconFileName: 'icon_dog_5.png',
        modalImage: 'plan-modal-basics.jpeg',
        progress: 2,
        subtitile: 'od 6 msc życia',
        title: 'Trening podstaw',
        description:
          'Szkolenie przeznaczone jest dla psów powyżej szóstego miesiąca życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).',
        meetings: 1,
        duration: 60,
        allMeetings: 10,
        price: 140,
        totalPrice: 1300,
        additionalText: 'Zajęcia odbywają się w&nbsp;miejscu wskazanym przez&nbsp;klienta.',
        longDescription: `<br/>
        Szkolenie przeznaczone jest dla psów powyżej szóstego miesiąca życia i&nbsp;składa się z&nbsp;10 spotkań (kurs) lub wybranych lekcji (zakres uzgadnia się&nbsp;indywidualnie).<br/> Szkolenie z zakresu posłuszeństwa nie jest metodą na rozwiązanie problemów behawioralnych psa.
        <br/>
        W przypadku występowania problemów z zachowaniem psa, należy skorzystać z konsultacji behawioralnej.
        <br/><br/>
            
        <b>Zakres szkolenia</b><br/><br/>
        <ol>
        <li>Zastosowanie klikera i&nbsp;kontakt&nbsp;wzrokowy.</li>
        <li>Targetowanie.</li>
        <li>Siad.</li>
        <li>Waruj/leżeć.</li>
        <li>Stój.</li>
        <li>Zostań.</li>
        <li>Dostawianie do nogi i&nbsp;chodzenie przy&nbsp;nodze.</li>
        <li>Przywołanie.</li>
        <li>Prawidłowa zabawa.</li>
        <li>Zostaw/weź.</li>
        <li>Na miejsce.</li>
        </ol>
        Organizator zastrzega sobie prawo do modyfikacji zakresu szkolenia dostosowując go do indywidualnych możliwości psa.
        <br/>
        <br/>
        `,
      },
    ],
  },
  {
    id: 'petSitting',
    title: 'Pet sitting',
    image: 'petsitting.jpeg',
    imageStyle: { backgroundSize: '110%', backgroundPositionY: '30%' },
    popup: false,
    plans: [
      {
        key: 'nextWalk',
        id: 'nextWalk',
        iconFileName: 'icon_dog_3.png',
        progress: 3,
        title: 'Spacer',
        subtitile: 'spacer z psem',
        description:
          'Wspólny spacer z&nbsp;psem nastawiony na&nbsp;zaspokojenie potrzeb psa w&nbsp;zakresie węszenia, eksploracji oraz aktywności ruchowej.',
        price: null,
      },
      {
        key: 'homeSitting',
        id: 'homeSitting',
        iconFileName: 'icon-intro-dog.png',
        progress: 3,
        title: 'Opieka nad psem',
        subtitile: 'indywidualna opieka nad psem w&nbsp;jego&nbsp;domu',
        description:
          'Opieka nad psem w domu klienta. Zakres opieki, cena i&nbsp;koszt usługi ustalane są&nbsp;indywidualnie w zależności od&nbsp;liczby zwierząt, zakresu obowiązków (spacery, posiłki, podawanie leków itp.),&nbsp;czasu trwania opieki oraz odległości do&nbsp;miejsca świadczenia usługi. ',
        price: null,
      },
    ],
  },
];
