/* eslint-disable react/prop-types */
import React from 'react';
import parse from 'html-react-parser';

import { PejoResponsiveModal } from '../../shared';
import './PlanModal.css';
import PlanModalImageMobile from './PlanModalImageMobile';
import components from './PlanModal.styles';

const PlanModal = ({ plan, onClose }) => {
  const { InfoBox, PriceBox } = components;
  return (
    <PejoResponsiveModal
      isOpen={!!plan}
      onClose={() => onClose()}
      title={plan?.title}
      subtitle={plan?.subtitile}
    >
      <div className="dialog-content-box">
        <InfoBox>
          <div style={{ height: '100%' }}>
            {plan?.additionalText && (
              <div className="additional-plan-info">{parse(plan?.additionalText)}</div>
            )}
            <p className="dialog-long-description">{parse(plan?.longDescription || '')}</p>
          </div>
        </InfoBox>
        <PriceBox>
          <div>
            <div>
              <h2 style={{ fontWeight: 600, textAlign: 'center', fontSize: '2.5rem' }}>
                {plan?.duration}&nbsp;minut
              </h2>
              <h4 style={{ textAlign: 'center' }}>czas trwania lekcji</h4>
            </div>
            <div style={{ padding: '30px 0' }}>
              <div className="main-plan-price-box">{plan?.price}&nbsp;PLN</div>
              <h4 style={{ textAlign: 'center', paddingTop: '10px' }}>cena jednego spotkania</h4>
            </div>
            <div>
              <div className="main-plan-price-box sale">
                <span style={{ paddingRight: '10px' }}>{plan?.totalPrice} PLN </span>
                {plan?.price * plan?.allMeetings - plan?.totalPrice > 0 && (
                  <span style={{ opacity: 0.5 }}>
                    {plan?.price * plan?.allMeetings - plan?.totalPrice}
                    &nbsp;PLN&nbsp;taniej!
                  </span>
                )}
              </div>
              <h4 style={{ textAlign: 'center', paddingTop: '10px' }}>
                Cena za kurs ({plan?.allMeetings}&nbsp;spotkań)
              </h4>
            </div>
          </div>
        </PriceBox>
        <br />
        {/* <div>
         
          <div style={{ padding: '15px 0' }}>
            
            {plan?.allMeetings > 1 && (
              <div className="main-plan-price-info">
                Cena za kurs (10&nbsp;spotkań):{' '}
                <div className="main-plan-price-box sale">
                  <span style={{ paddingRight: '10px' }}>{plan?.totalPrice} PLN </span>
                  {plan?.price * plan?.allMeetings - plan?.totalPrice > 0 && (
                    <span style={{ opacity: 0.5 }}>
                      {plan?.price * plan?.allMeetings - plan?.totalPrice}
                      &nbsp;PLN&nbsp;taniej!
                    </span>
                  )}
                </div>
              </div>
            )}
            {plan?.additionalText && (
              <div className="additional-plan-info">{parse(plan?.additionalText)}</div>
            )}
          </div>
          <p className="dialog-long-description">{parse(plan?.longDescription || '')}</p>
        </div>
        <PlanModalImageMobile filename={plan?.modalImage} alt="Cute dog" /> */}
      </div>
    </PejoResponsiveModal>
  );
};

export default PlanModal;
